import { useEffect, useState, useRef } from "react";

const storedPass = window.localStorage.getItem("pass");

const passwordHash =
  "bbe7a727217b335afc5aa51084477fb693269a2a09e7c2e6d009594c8820baaa";

async function digestMessage(str) {
  const msgUint8 = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

function App() {
  const element = useRef();
  const [data, setData] = useState();
  const [password, setPassword] = useState(storedPass);
  const [scrollOffset, setScrollOffset] = useState(0);

  const onChange = (e) => {
    (async () => {
      const pass = e.target.value.toLowerCase();
      const hash = await digestMessage(pass);
      if (hash === passwordHash) {
        setPassword(pass);
        window.localStorage.setItem("pass", pass);
      }
    })();
  };

  useEffect(() => {
    const current = element.current;

    const onScroll = (e) => {
      setScrollOffset(e.target.scrollTop);
    };

    current.addEventListener("scroll", onScroll);

    return () => {
      current.removeEventListener("scroll", onScroll);
    };
  }, [element]);

  useEffect(() => {
    if (!password) {
      return;
    }

    if (password) {
      (async () => {
        const response = await fetch("/data.json", {
          headers: { "x-api-key": password },
        });
        const data = await response.json();
        setData(data);
      })();
    }
  }, [password]);

  const onArrowClick = () => {
    console.log("click");
    element.current.scrollTo({
      top: element.current.offsetHeight - 240,
      behavior: "smooth",
    });
  };

  return (
    <div className="App" ref={element}>
      <section className="top">
        <div className="flowers"></div>
        <h1>Charlotte and Jack</h1>
        {!password && !data && (
          <input onChange={onChange} placeholder="password"></input>
        )}
        {password && !data && (
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        {data && (
          <button className="arrow-button" onClick={onArrowClick}>
            <div className={`down-arrow ${scrollOffset < 50 && "show"}`}></div>
          </button>
        )}
      </section>
      {data && (
        <>
          <section>
            <h3>Date</h3>
            <p>{data.date}</p>
          </section>
          <section className="location">
            <h3>Location</h3>
            <p>{data.location}</p>
            <img alt="map" src={`${data.mapImgUrl}`} />
            <a target="_blank" href={data.mapLink}>
              Google Maps
            </a>
          </section>
          <section>
            <h3>Itinerary</h3>
            <div className="list">
              <p>1:30-1:45pm</p>
              <p>Please be there and ready to be seated 🪑</p>
              <p>2pm</p>
              <p>Ceremony ⚭</p>
              <p>2:30pm</p>
              <p>Champagne and Canapés on the lawn 🥂</p>
              <p>4:30pm</p>
              <p>BBQ in the marquee 🍽</p>
              <p>5:45pm</p>
              <p>Cocktail Bar opens 🍸, DJ starts 🎵</p>
              <p>12am</p>
              <p>Curfew</p>
            </div>
          </section>
          <section>
            <h3>Other Info</h3>
            <p>You may want to bring flat shoes for dancing</p>
            <p>
              Hopefully it doesn't rain, but it may be good to pack a brolly
            </p>
            <p>
              If you're having trouble finding the venue, or you need to contact
              anyone for any reason, please ring Joe on{" "}
              <a href={`tel:${data.joe.replace(" ", "")}`}>{data.joe}</a>
            </p>
          </section>
          <section>
            <h3>Parking</h3>
            <p>{data.parking}</p>
          </section>
          <section>
            <h3>Taxi</h3>
            <a href={data.taxiUrl} target="_blank">
              {data.taxiName}
            </a>
          </section>
          <section>
            <h3>Sunday</h3>
            <p>
              We're having a very informal picnic on Sunday early afternoon in{" "}
              {data.picnic}, for anyone wanting to come along. Weather and
              hangover permitting
            </p>
          </section>
          <section>
            <h3>Gifts</h3>
            <p>
              Gifts really aren't necessary, we're just happy that you're
              coming. A few people have asked though, and we've set a money pot
              for our honeymoon at{" "}
              <a href={data.pot} target="_blank">
                {data.pot}
              </a>
              . We're hoping to go to California in September 😄
            </p>
          </section>
          <hr />
          <section>
            <p>We can't wait to see you all!</p>
            <p>♥</p>
          </section>
        </>
      )}
    </div>
  );
}

export default App;
